import { Link } from "gatsby"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FormattedMessage, injectIntl, useIntl } from "gatsby-plugin-intl"
import { Container, Col, Row } from "react-bootstrap"
import Layout from "../components/Layout"
import SearchEngineOptimization from "../components/SearchEngineOptimization"
import styled from "styled-components"

const StoreContainer = styled.div`
    margin: 5rem 0 3rem;
`

const LogoContainer = styled.div`
    margin-left: 1.25rem;
`

const TextJustify = styled.div`
    @media (max-width: 416px) {
        font-size: 1.25rem;
    }
    text-align: justify;
    padding: 2rem;
`

const FullHeightRow = styled(Row)`
    min-height: 90vh;
`

const ColWithMargin = styled(Col)`
    margin-bottom: 5rem;
`

const ContainerWithMargin = styled(Container)`
    margin-bottom: 7.5rem;
`

export const StyledLink = styled(Link)`
    @media (max-width: 416px) {
        display: inline-block;
        margin-bottom: 1rem;
        margin-left: 0rem !important;
    }
    @media (max-width: 428px) {
        margin-left: 0.25rem !important;
    }
`

const IndexPage = () => {
    const intl = useIntl()
    return (
        <Layout pageInfo={{ pageName: "index" }}>
            <SearchEngineOptimization
                lang={intl.locale}
                title={intl.formatMessage({ id: "pages.index.seo.title" })}
                keywords={[
                    `electric`,
                    `vehicle`,
                    `charging`,
                    `bitcoin`,
                    `lightning`
                ]}
            />
            <ContainerWithMargin>
                <FullHeightRow xs={1} lg={2} className="align-items-center">
                    <ColWithMargin
                        xs={{ order: "last" }}
                        lg={{ order: "first" }}
                    >
                        <StaticImage
                            alt="Satimoto"
                            src="../images/phone-1317x2650.png"
                            placeholder="none"
                            width={400}
                        />
                    </ColWithMargin>
                    <ColWithMargin
                        xs={{ order: "first" }}
                        lg={{ order: "last" }}
                    >
                        <LogoContainer>
                            <StaticImage
                                alt="Satimoto"
                                src="../images/logo-300dpi.png"
                                placeholder="none"
                                width={200}
                            />
                        </LogoContainer>

                        <h1 className="hero">
                            <FormattedMessage
                                id="pages.index.hero.title"
                                defaultMessage="Hello Satimoto"
                                values={{
                                    gradient: (content) => (
                                        <span className="text-gradient">
                                            {content}
                                        </span>
                                    )
                                }}
                            />
                        </h1>
                        <p className="hero">
                            <FormattedMessage
                                id="pages.index.hero.text"
                                defaultMessage="Recharge your vehicle with Lightning"
                            />
                        </p>
                        <StoreContainer>
                            <StyledLink
                                to="https://play.google.com/store/apps/details?id=com.satimoto"
                                target="_blank"
                            >
                                <img
                                    src="/buttons/download-google-play.png"
                                    alt="Google Play"
                                />
                            </StyledLink>
                            <StyledLink
                                to="https://apps.apple.com/app/satimoto/id6444064066"
                                target="_blank"
                                style={{ marginLeft: "1.25rem" }}
                            >
                                <img
                                    src="/buttons/download-app-store.png"
                                    alt="App Store"
                                />
                            </StyledLink>
                        </StoreContainer>
                    </ColWithMargin>
                </FullHeightRow>
                <Row xs={1} lg={2} className="align-items-center mb-5">
                    <Col>
                        <div className="h1 text-gradient">
                            Accountless Charging
                        </div>
                        <TextJustify className="h4">
                            <p>
                                No signup, no personal details, no credit cards.
                                We don't need them!
                            </p>
                            <p>
                                Enjoy the privacy of charging your electric
                                vehicle and the freedom to roam without
                                compromising your data.
                            </p>
                        </TextJustify>
                    </Col>
                    <Col>
                        <StaticImage
                            alt="Satimoto"
                            src="../images/phone-accountless.png"
                            placeholder="none"
                            width={400}
                        />
                    </Col>
                </Row>
                <Row xs={1} lg={2} className="align-items-center mb-5">
                    <Col
                        xs={{ order: "last" }}
                        lg={{ order: "first" }}
                    >
                        <StaticImage
                            alt="Satimoto"
                            src="../images/phone-charge-point.png"
                            placeholder="none"
                            width={400}
                        />
                    </Col>
                    <Col
                        xs={{ order: "first" }}
                        lg={{ order: "last" }}
                    >
                        <div className="h1 text-gradient">
                            Low Transparent Fees
                        </div>
                        <TextJustify className="h4">
                            <p>
                                We don't use traditional payment providers like
                                VISA or Mastercard, so we can pass on the
                                merchant fees to you.
                            </p>
                            <p>
                                With the average roaming fee at around 10%, that
                                means we can offer a 7% commission fee per
                                charge.
                            </p>
                        </TextJustify>
                    </Col>
                </Row>
                <Row xs={1} lg={2} className="align-items-center mb-5">
                    <Col>
                        <div className="h1 text-gradient">Stream Payments</div>
                        <TextJustify className="h4">
                            <p>
                                We leverage the security of the Bitcoin protocol
                                and the speed of the Lightning Network to stream
                                payments.
                            </p>
                            <p>
                                Simply fund your application with Bitcoin and
                                pay in real-time as you charge your electric
                                vehicle.
                            </p>
                        </TextJustify>
                    </Col>
                    <Col>
                        <StaticImage
                            alt="Satimoto"
                            src="../images/phone-streaming.png"
                            placeholder="none"
                            width={400}
                        />
                    </Col>
                </Row>
                <Row xs={1} lg={2} className="justify-content-center">
                    <Col>
                        <p className="hero">
                            <FormattedMessage
                                id="pages.index.hero.text"
                                defaultMessage="Recharge your vehicle with Lightning"
                            />
                        </p>
                        <StoreContainer className="mt-0 mb-0">
                            <StyledLink
                                to="https://play.google.com/store/apps/details?id=com.satimoto"
                                target="_blank"
                            >
                                <img
                                    src="/buttons/download-google-play.png"
                                    alt="Google Play"
                                />
                            </StyledLink>
                            <StyledLink
                                to="https://apps.apple.com/app/satimoto/id6444064066"
                                target="_blank"
                                style={{ marginLeft: "1.25rem" }}
                            >
                                <img
                                    src="/buttons/download-app-store.png"
                                    alt="App Store"
                                />
                            </StyledLink>
                        </StoreContainer>
                    </Col>
                </Row>{" "}
            </ContainerWithMargin>
        </Layout>
    )
}

export default injectIntl(IndexPage)
